
import NavBar from "@/components/common/nav-bar.vue";
import {Vue} from "@/extensions";
import {Options} from "vue-class-component";
import {convertParamsToObject} from "@/utils"
// @ts-ignore
import de from 'element-plus/dist/locale/de.mjs'
// @ts-ignore
import en from 'element-plus/dist/locale/en.mjs'
// @ts-ignore
import it from 'element-plus/dist/locale/it.mjs'
// @ts-ignore
import sk from 'element-plus/dist/locale/sk.mjs'
// @ts-ignore
import cz from 'element-plus/dist/locale/cs.mjs'
import auditLogService, {AuditLogType} from "@/services/audit-log-service";

@Options({
  components: {
    NavBar
  }
})
export default class App extends Vue {

  blockPointerEvents: boolean = false
  isLoggedAsOther: boolean = false
  loggedUser: any = null
  currentUser: any = null

  languages = {
    'en': en,
    'it': it,
    'cz': cz,
    'sk': sk,
    'de': de
  } as any

  mounted() {
    document.onkeydown = this.onGlobalEscape
    this.refreshConfigurations()
    this.refreshMainLanguage()
    this.checkIfCallback()
    this.emitter.on("toggle-global-events", this.toggleBlockEvents)
    this.emitter.on("reload-footer-logged-as", this.reloadFooterLoggedAs)
    this.reloadFooterLoggedAs()
    this.getUserInfo((user: any) => {
      this.loggedUser = user
      this.$forceUpdate()
    }, true)
  }

  beforeUnmount() {
    this.emitter.off("toggle-global-events", this.toggleBlockEvents)
    this.emitter.off("reload-footer-logged-as", this.reloadFooterLoggedAs)
  }

  get locale() {
    return this.languages[this.currentLanguage] || de
  }

  checkIfCallback() {
    let params = convertParamsToObject(window.location.href.split("#/")[1])
    if (params?.code && params?.state) this.finishDatevAuth(params)

    params = convertParamsToObject(window.location.href.split("?")[1])
    if (params?.code) this.finishMS365Auth(params)
  }

  finishDatevAuth(params: any) {
    this.lbFetch({
      url: "/api/datev/auth-finish", method: "POST", data: params, showError: false,
      success: (result) => {
        try {
          const hasClient = !!result.data?.datev_client_id
          auditLogService.saveAuditLogInfo(
              'tenant',
              AuditLogType.info,
              hasClient ? 'connected_client_info' : 'connected_info',
              result.data,
              "datev_access",
              result.data?.tenant_id
          )
        } catch (error) {
          console.error('Fehler beim Speichern des Audit-Logs:', error)
        }
        this.$router.push({name: "configuration", query: {tab: this.$t('tenant.datev'), success: result.success}})
      }
    })
  }

  finishMS365Auth(params: any) {
    if (params.state)
      this.lbFetch({
        url: `/api/ms-365/auth-finish/${params.state}`, method: "POST", data: params, showError: false,
        success: (result) => {
          this.removeQueryParams()
          this.$router.push({
            name: "configuration",
            query: {tab: this.$t('tenant.tab_mailbox'), success: result.success}
          })
        }
      })
    else
      this.lbFetch({
        url: `/api/ms-365/auth-finish`, method: "POST", data: params, showError: false,
        success: (result) => {
          this.removeQueryParams()
          try {
            auditLogService.saveAuditLogInfo(
                'tenant',
                AuditLogType.info,
                'connected_info',
                result.data,
                "ms365_access",
                result.data?.tenant_id
            )
          } catch (error) {
            console.error('Fehler beim Speichern des Audit-Logs:', error)
          }
          this.$router.push({
            name: "configuration",
            query: {tab: this.$t('configuration.ms365.description'), success: result.success}
          })
        }
      })
  }

  removeQueryParams() {
    const url = window.location.href;
    if (url.includes('?')) {
      const baseUrl = url.split('?')[0]
      window.history.replaceState({}, document.title, baseUrl);
    }
  }

  toggleBlockEvents() {
    this.blockPointerEvents = !this.blockPointerEvents
  }

  globalDismiss(event: any) {
    if (event.target.tagName == "DIV") {
      this.emitter.emit("global_dismiss")
    }
  }

  onGlobalEscape(evt: any) {
    evt = evt || window.event;
    if (("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) || evt.keyCode === 27) this.stopTours()
  }

  reloadFooterLoggedAs() {
    this.isLoggedAsOther = this.isLoggedAsOtherUser()
    if (this.isLoggedAsOther) this.getUserInfo((user: any) => {
      this.currentUser = user
      this.$forceUpdate()
    })
    else this.currentUser = null
  }
}

