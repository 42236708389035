import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import sentry from './sentry'
import apolloGraphql from "./apollo-graphql";
import ElementPlus from 'element-plus'
// @ts-ignore
import de from 'element-plus/dist/locale/de.mjs'
import mitt from "mitt";
import {lbXhrAuthentication} from "@/extensions";

import 'element-plus/theme-chalk/index.css';
import './style/style.scss';
import './common-types'

// @ts-ignore
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import i18n from "./locales/i18n"

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {Chart, registerables} from "chart.js";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import * as VueModule from 'vue';

//@ts-ignore
window['Vue'] = VueModule;
Chart.register(...registerables);
library.add(fas)
library.add(far)
dayjs.Ls.en.weekStart = 1
dayjs.extend(utc)

lbXhrAuthentication().then(() => {
    setTimeout(() => {
        const app = createApp(App)
        app.use(sentry)
        app.use(apolloGraphql)
        app.use(router)
        app.use(ElementPlus, {locale: de})
        app.use(Vue3Tour)
        app.use(i18n)
        app.config.globalProperties.emitter = mitt()
        app.component('fa', FontAwesomeIcon)
        app.mount('#app')
    }, 250)

})
