import gql from "graphql-tag";
import {GqlTransaction} from "@/services/gql-transaction";
import {AuditLog} from "@/services/audit-log-service";
import {gqlMutationRaw} from "@/apollo-graphql/utils";
import dayjs from "dayjs";

class AuditLogActionsImpl {

    constructor() {
    }


    getGqlToSave(objName: string) {
        const mutation = `
            mutation saveNewAuditLog_${objName}(
                $${objName}: audit_log_insert_input!
            ) {
                ${objName}:insert_audit_log_one(object: $${objName}) {
                    id
                }
            }
        `
        return gql(mutation)
    }

    save(auditLog: AuditLog, transaction?: GqlTransaction) {
        const objName = this.generateRandomAlphabeticString(10) + dayjs().millisecond()
        let data: any = {}
        data[objName] = auditLog
        if (transaction) transaction.execute(this.getGqlToSave(objName), data)
        else gqlMutationRaw(this.getGqlToSave(objName), data)
    }

    generateRandomAlphabeticString(length: number) {
        const letters = 'abcdefghijklmnopqrstuvwxyz'
        let result = ''
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * letters.length)
            result += letters[randomIndex]
        }
        return result
    }
}

const AuditLogActions = new AuditLogActionsImpl()
export default AuditLogActions
